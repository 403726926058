import React from 'react';

const ChatbotMessageAvatar = ({botAvatar}) => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <img src={botAvatar} className="react-chatbot-kit-bot-avatar-icon" />
      </div>
    </div>
  );
};

export default ChatbotMessageAvatar;
