import React, { useState, useRef, useEffect, SetStateAction, useContext } from 'react';
import ConditionallyRender from 'react-conditionally-render';

import UserChatMessage from '../UserChatMessage/UserChatMessage';
import ChatbotMessage from '../ChatbotMessage/ChatbotMessage';
import { ConfigContext } from '../../App';
import { CHATBOT_HOST, CHAT_API, DEBUG_MODE } from '../../chatbot/config';

import {
  botMessage,
  userMessage,
  customMessage,
  createChatMessage,
  createChatBotMessage,
  shouldShowLeadForm
} from './chatUtils';

import paperPlane from "../../assets/icons/paperPlane.svg"
import chatBotIcon from "../../assets/icons/chatBotIcon.png"
import refreshIcon from "../../assets/icons/refreshIcon.png"
import closeIcon from "../../assets/icons/closeIcon.png"
import './Chat.css';
import {
  ICustomComponents,
  ICustomMessage,
  ICustomStyles,
} from '../../interfaces/IConfig';
import { IMessage } from '../../interfaces/IMessages';
import { string } from 'prop-types';

interface IChatProps {
  setState: React.Dispatch<SetStateAction<any>>;
  widgetRegistry: any;
  messageParser: any;
  actionProvider: any;
  customComponents: ICustomComponents;
  botName: string;
  botAvatar: string;
  customStyles: ICustomStyles;
  headerText: string;
  customMessages: ICustomMessage;
  placeholderText: string;
  validator: (input: string) => Boolean;
  state: any;
  setMessageContainerRef: React.Dispatch<SetStateAction<any>>;
  disableScrollToBottom: boolean;
  messageHistory: IMessage[] | string;
  parse?: (message: string) => void;
  actions?: object;
}

const Chat = ({
  state,
  setState,
  widgetRegistry,
  messageParser,
  parse,
  customComponents,
  actionProvider,
  botName,
  botAvatar,
  customStyles,
  headerText,
  customMessages,
  placeholderText,
  validator,
  setMessageContainerRef,
  disableScrollToBottom,
  messageHistory,
  actions,
}: IChatProps) => {
  const { messages } = state;
  const chatContainerRef = useRef(null);
  const {botConfig, setBotConfig, setMessageHistory} = useContext(ConfigContext);

  const [input, setInputValue] = useState('');

  const scrollIntoView = () => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef?.current?.scrollHeight;
      }
    }, 50);
  };

  useEffect(() => {
    if (disableScrollToBottom) return;
    scrollIntoView();
  });

  useEffect(() => {
    setMessageContainerRef(chatContainerRef);
  }, [chatContainerRef.current]);

  const showAvatar = (messages: any[], index: number) => {
    // if (index === 0) return true;
    // const lastMessage = messages[index - 1];
    // if (lastMessage === undefined) return true;
    // if (lastMessage.type === 'bot' && !lastMessage.widget) {
    //   return false;
    // }
    return true;
  };

  const customButtonStyle = { backgroundColor: '' };
  if (customStyles && customStyles.chatButton) {
    customButtonStyle.backgroundColor = customStyles.chatButton.backgroundColor;
  }

  let header = `Conversation with ${botName}`;
  if (headerText) {
    header = headerText;
  }

  let placeholder = 'Write your message here';
  if (placeholderText) {
    placeholder = placeholderText;
  }

  let chatbotAvatarUrl = chatBotIcon;
  if (botAvatar !=undefined) {
    chatbotAvatarUrl = botAvatar;
  }

  let lang = 'English';
  if (botConfig.lang != undefined){
    lang = botConfig.lang;
  }

  const renderMessages = (messages) => {
    return messages.map((messageObject: IMessage, index: number) => {
      if (botMessage(messageObject)) {
        return (
          <React.Fragment key={messageObject.id}>
            {renderChatbotMessage(messageObject, index)}
          </React.Fragment>
        );
      }

      if (userMessage(messageObject)) {
        return (
          <React.Fragment key={messageObject.id}>
            {renderUserMessage(messageObject)}
          </React.Fragment>
        );
      }

      if (customMessage(messageObject, customMessages)) {
        return (
          <React.Fragment key={messageObject.id}>
            {renderCustomMessage(messageObject)}
          </React.Fragment>
        );
      }
    });
  };

  const renderCustomMessage = (messageObject: IMessage) => {
    const customMessage = customMessages[messageObject.type];

    const props = {
      setState,
      state,
      scrollIntoView,
      actionProvider,
      payload: messageObject.payload,
      actions,
    };

    if (messageObject.widget) {
      const widget = widgetRegistry.getWidget(messageObject.widget, {
        ...state,
        scrollIntoView,
        payload: messageObject.payload,
        actions,
      });
      return (
        <>
          {customMessage(props)}
          {widget ? widget : null}
        </>
      );
    }

    return customMessage(props);
  };

  const renderUserMessage = (messageObject: IMessage) => {
    const widget = widgetRegistry.getWidget(messageObject.widget, {
      ...state,
      scrollIntoView,
      payload: messageObject.payload,
      actions,
    });
    return (
      <>
        <UserChatMessage
          message={messageObject.message}
          key={messageObject.id}
          customComponents={customComponents}
        />
        {widget ? widget : null}
      </>
    );
  };

  const renderChatbotMessage = (messageObject: IMessage, index: number) => {
    let withAvatar;
    if (messageObject.withAvatar) {
      withAvatar = messageObject.withAvatar;
    } else {
      withAvatar = showAvatar(messages, index);
    }

    const chatbotMessageProps = {
      ...messageObject,
      setState,
      state,
      customComponents,
      widgetRegistry,
      messages,
      actions,
    };

    if (messageObject.widget) {
      const widget = widgetRegistry.getWidget(chatbotMessageProps.widget, {
        ...state,
        scrollIntoView,
        payload: messageObject.payload,
        actions,
      });
      return (
        <>
          <ChatbotMessage
            customStyles={customStyles.botMessageBox}
            withAvatar={withAvatar}
            botAvatar={chatbotAvatarUrl}
            {...chatbotMessageProps}
            key={messageObject.id}
          />
          <ConditionallyRender
            condition={!chatbotMessageProps.loading}
            show={widget ? widget : null}
          />
        </>
      );
    }

    return (
      <ChatbotMessage
        customStyles={customStyles.botMessageBox}
        key={messageObject.id}
        withAvatar={withAvatar}
        botAvatar={chatbotAvatarUrl}
        {...chatbotMessageProps}
        customComponents={customComponents}
        messages={messages}
        setState={setState}
      />
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validator && typeof validator === 'function') {
      if (validator(input)) {
        handleValidMessage();
        if (parse) {
          return parse(input);
        }
        messageParser.parse(input);
      }
    } else {
      handleValidMessage();
      if (parse) {
        return parse(input);
      }
      messageParser.parse(input);
    }
  };

  const handleSendMessage = async(allMessages) => {
    const fakeMessage = createChatBotMessage("", {loading:true});
    setState((state:any) => ({
      ...state,
      messages:[...allMessages, fakeMessage]
    }));
    const chat_url = DEBUG_MODE ? 'http://'+CHATBOT_HOST+CHAT_API : CHAT_API; 
    let response = await fetch(chat_url, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        'chatbot_id': botConfig.chatbotId,
        'conversation_id': botConfig.conversationId,
        'messages': allMessages,
        'lang': botConfig.lang,
      })
    });
    let result = await response.json();
    if (result.error != 0){
      console.error("Error when getting an answer");
    }
    const messageOptions = {
      'loading': false,
    };
    if (shouldShowLeadForm(allMessages, botConfig.leads)){
      messageOptions.widget = 'leadForm';
    }
    const botMessage = createChatBotMessage(result.message, messageOptions)
  
    setState((state:any) => ({
      ...state,
      messages:[...allMessages, botMessage]
    }));
  }

  const handleValidMessage = () => {
    const allMessages = [...state.messages, createChatMessage(input, 'user')];
    setState((state: any) => ({
      ...state,
      messages: allMessages,
    }));
    scrollIntoView();
    setInputValue('');
    handleSendMessage(allMessages);
  };

  const renderCloseWindowButton = () => {
    if (botConfig.inIframe){
      return <>
        <button style={{cursor: 'pointer'}} className='chatbot-action-button'
            onClick={handleClose}
          >
            <img className='chatbot-action-close-icon' src={closeIcon}/>
        </button>
      </>;
    }else{
      return undefined;
    }
  }

  const handleClose = ()=>{
    window.parent.postMessage('x_chat_window_closed', '*');
  }

  const handleRefresh = ()=>{
    setState((state:any) => ({
      ...state,
      messages:[]
    }));
    setMessageHistory([]);
  }

  const handleLanguageChange = (e)=>{
    setBotConfig({
      ...botConfig,
      lang: e.target.value
    });
  }
  const handleSuggestedMessageClick = (input)=>{
    const allMessages = [...state.messages, createChatMessage(input, 'user')];
    setState((state: any) => ({
      ...state,
      messages: allMessages,
    }));
    scrollIntoView();
    setInputValue('');
    handleSendMessage(allMessages);
  }

  const renderSuggestedMessages = ()=>{
    if(botConfig.suggestedMessages != null && botConfig.suggestedMessages.length > 0){
      return botConfig.suggestedMessages.map(function(message){
        return (<button 
                className="suggested-message-item"
                onClick={() => handleSuggestedMessageClick(message)}
                >
                  {message}
                </button>);
      });
    }
  }

  return (
    <div className="react-chatbot-kit-chat-container">
      <div className="react-chatbot-kit-chat-inner-container">
        <ConditionallyRender
          condition={!!customComponents.header}
          show={
            customComponents.header && customComponents.header(actionProvider)
          }
          elseShow={
            <div className="react-chatbot-kit-chat-header">
              <div className="react-chatbot-kit-bot-avatar-container"> 
                <img src={chatbotAvatarUrl} className="react-chatbot-kit-bot-avatar" />
              </div>
              <div>{header}</div>
              <div className='chatbot-header-spacer'></div>
              <div className='chatbot-action-container'>
                <select className='chatbot-language-select'
                  onChange={handleLanguageChange}
                  value={lang}
                >
                  <option value="English">English</option>
                  <option value="Thai">Thai</option>
                  <option value="Chinese">中文</option>
                  <option value="Japanese">Japanese</option>
                </select>
                <button style={{cursor: 'pointer'}} className='chatbot-action-button'
                  onClick={handleRefresh}
                >
                  <img className='chatbot-action-refresh-icon' src={refreshIcon}/>
                </button>
                {renderCloseWindowButton()}
              </div>
            </div>
          }
        />
        <div
          className="react-chatbot-kit-chat-message-container"
          ref={chatContainerRef}
        >
          {renderMessages(messageHistory)}
          {renderMessages(messages)}
          <div style={{ paddingBottom: '15px' }} />
        </div>

        <div className="react-chatbot-kit-chat-input-container">
          <div className="suggested-messages-container">
              {renderSuggestedMessages()}
          </div>
          <form
            className="react-chatbot-kit-chat-input-form"
            onSubmit={handleSubmit}
          >
            <input
              className="react-chatbot-kit-chat-input"
              placeholder={placeholder}
              value={input}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              className="react-chatbot-kit-chat-btn-send"
              style={customButtonStyle}
              disabled={input===""}
            >
              <img src={paperPlane} className="react-chatbot-kit-chat-btn-send-icon"/>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
