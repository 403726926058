import {
  createChatBotMessage
} from '../components/Chat/chatUtils';

import LeadForm from '../components/Forms/LeadForm';

export const DEBUG_MODE = false;

export const config = {
  botName: "Chatbot",
  initialMessages: [
    createChatBotMessage(`Hello. how can I help you today :)`)
  ],
  widgets:[{
    widgetName: "leadForm",
    widgetFunc: (props)=> <LeadForm {...props}/>
  }]
};

let ADMIN_HOST_;
let CHATBOT_HOST_;
if (DEBUG_MODE){
  ADMIN_HOST_ = 'http://127.0.0.1:9000';
  CHATBOT_HOST_ = '127.0.0.1:5000';
}else{
  ADMIN_HOST_ = 'https://www.xcourse.sg';
  CHATBOT_HOST_ = '';
}

export const CONVERSATION_CONFIG_API = '/api/v1/conversation/get';
export const MESSAGE_HISTORY_API = '/api/v1/messages/get'
export const CHAT_API = '/api/v1/conversation/chat';
export const CONVERSATION_HISTORY_API = '/api/v1/conversation/history';
export const LEAD_API = '/api/v1/leads/create';
export const CHATBOT_HOST = CHATBOT_HOST_;
export const ADMIN_HOST =  ADMIN_HOST_;


export const inIframe = () => {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}