import React, { useEffect, useState, useCallback } from "react";
import Cookies from 'js-cookie';

import Chatbot from './components/Chatbot/Chatbot';
import ActionProvider from "./chatbot/ActionProvider";
import MessageParser from "./chatbot/MessageParser";
import {config, inIframe, ADMIN_HOST, DEBUG_MODE, CHATBOT_HOST, CONVERSATION_CONFIG_API, CONVERSATION_HISTORY_API } from "./chatbot/config";

export const ConfigContext = React.createContext(null);

const App = () => {
  const [botConfig, setBotConfig] = useState(config);
  const [messageHistory, setMessageHistory] = useState([]);

  const loadConversationHistory = useCallback(async (conversationId) => {
    const history_url = DEBUG_MODE ? 'http://' + CHATBOT_HOST + CONVERSATION_HISTORY_API : CONVERSATION_HISTORY_API;
    let response = await fetch(history_url,
    {
      method:'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        'conversation_id': conversationId
      })
    });
    let result = await response.json();
    if (result.error === 0){
      setMessageHistory(result.data);
    }else{
      setMessageHistory(botConfig.initialMessages);
      console.error("Failed to load chat history");
    }
  },[])

  const fetchConfigAndInitialMessages = useCallback(async(conversationId) => {
    const config_url = DEBUG_MODE ? 'http://'+ CHATBOT_HOST + CONVERSATION_CONFIG_API : CONVERSATION_CONFIG_API;
    let response = await fetch(config_url,
    {
      method:'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        "conversation_id": conversationId
      })
    });
    let result = await response.json();
    if (result.error == 0) {
      const botConf = {...config};
      botConf.botName = result.data.name;
      botConf.lang = result.data.lang || 'English';
      botConf.initialMessages =result.data.initial_messages;
      botConf.suggestedMessages =result.data.suggested_messages;
      botConf.conversationId = conversationId;
      botConf.chatbotId = result.data.chatbot_id;
      botConf.leads = result.data.leads;
      botConf.inIframe = inIframe();
      if (result.data.bot_avatar != undefined && result.data.bot_avatar.startsWith('/')){
        botConf.botAvatar = ADMIN_HOST + result.data.bot_avatar;
      }else {
        botConf.botAvatar = result.data.bot_avatar;
      }
      setBotConfig(botConf);
    }
  }, []);

  
  useEffect(() => {
    let conversationId = document.querySelector('#x-conversation-id').value;
    if (conversationId != undefined) {
      fetchConfigAndInitialMessages(conversationId);
    }
  }, [fetchConfigAndInitialMessages]);

  useEffect(() => {
    let conversationId = document.querySelector('#x-conversation-id').value;
    if (conversationId != undefined) {
      loadConversationHistory(conversationId);
    }
  }, [loadConversationHistory]);
  
  return (
    <div className="App">
      <ConfigContext.Provider value={{botConfig: botConfig, setBotConfig:setBotConfig, messageHistory: messageHistory, setMessageHistory: setMessageHistory}}>
        <Chatbot
          config={botConfig}
          messageParser={MessageParser}
          messageHistory={messageHistory}
          actionProvider={ActionProvider}
          headerText={config.headerText}
        />
      </ConfigContext.Provider>
    </div>
  );
}

export default App;