import { useState, useContext } from "react";
import './LeadForm.css';
import { validateEmail, validatePhoneNumber } from "./utils";
import { ConfigContext } from '../../App';

import paperPlane from "../../assets/icons/paperPlane.svg"
import {LEAD_API, CHATBOT_HOST, DEBUG_MODE} from '../../chatbot/config';


const LeadForm = (props) => {
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [valid, setValid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {botConfig, setBotConfig} = useContext(ConfigContext);

    const setEmailInput = (email)=>{
        setEmail(email);
        if (email != undefined && validateEmail(email)){
            setValid(true);
        }
    }

    const setNumberInput = (number)=>{
        setNumber(number);
        if (number != undefined && validatePhoneNumber(number)){
            setValid(true);
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const chat_url = DEBUG_MODE ? 'http://'+CHATBOT_HOST+LEAD_API : LEAD_API; 
        let response = await fetch(chat_url, {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          body: JSON.stringify({
            'chatbot_id': botConfig.chatbotId,
            'conversation_id': botConfig.conversationId,
            'email': email,
            'number': number,
          })
        });
        let result = await response.json();
        if (result.error != 0){
          console.error("Error when getting an answer");
        }else{
            setSubmitted(true);
            setBotConfig({...botConfig, leads: botConfig.leads+1})
        }
    }

    const getContactSavedBanner = ()=>{
        if (submitted) {
            return <>
                <p className="lead-form-saved-contact-banner">Your contact has been saved</p>
            </>;
        }else{
            return undefined;
        }
    }
    

    return (
        <div className="react-chatbot-kit-lead-form-container">
            <div className="lead-form-message">
              <div>
                <div>Please leave your contact for us to get back to you.</div>
                {getContactSavedBanner()}
                <form
                    className="lead-input-form"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <div className="form-label">Email:</div>       
                        <input
                            className="form-input"
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="form-label">Number:</label>
                        <input
                            className="form-input"
                            placeholder="phone number"
                            value={number}
                            onChange={(e) => setNumberInput(e.target.value)}
                        />
                    </div>
                    <button
                        className="form-btn"
                        disabled={!valid && !submitted}
                    >
                        Submit <img src={paperPlane} className="react-chatbot-kit-chat-btn-send-icon"/>
                    </button>
                </form>
              </div>
            </div>
        </div>
    );
  };
  
  export default LeadForm;
  